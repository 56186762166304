<template>
  <div class="filter-sidebar">
    <div class="filter-sidebar-inner-scroll">
      <h2>सेवा दिने कार्यालय</h2>
      <div
        class="services-filter"
        v-for="(ele, index) in service_provider_arr"
        :key="`provider-${index}`"
      >
        <label class="radio-wrapper services-filter__label">
          {{ ele.label }}
          <input
            type="radio"
            class="map-filter__input"
            :value="ele.value"
            v-model="service_provider"
            @click="changeServiceProvider"
          />
          <span class="radiomark"></span>
        </label>
      </div>
      <h2>सेवाको प्रकार</h2>
      <div
        class="services-filter"
        v-for="(ele, index) in service_type_arr"
        :key="`type-${index}`"
      >
        <label class="radio-wrapper services-filter__label">
          {{ ele.label }}
          <input
            type="radio"
            :value="ele.value"
            v-model="service_type"
            class="map-filter__input"
            @click="changeServiceType"
          />
          <span class="radiomark"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      service_provider: "",
      service_type: "",
      service_provider_arr: [
        {
          label: "वडा",
          value: "वडा"
        },
        {
          label: "पालिका",
          value: "नगरपालिका"
        }
      ],
      service_type_arr: [
        {
          label: "व्यक्तिगत",
          value: "व्यक्तिगत"
        },
        {
          label: "सम्पति",
          value: "सम्पति"
        },
        {
          label: "व्यवसाय",
          value: "व्यवसाय"
        },
        {
          label: "संघसंस्थाहरु",
          value: "संघसंस्थाहरु"
        },
        {
          label: "अन्य",
          value: "अन्य"
        }
      ]
    };
  },
  methods: {
    changeServiceProvider(event) {
      let tmp = event.target.value;

      if (event.target.value === this.service_provider) {
        this.service_provider = "";
        tmp = "";
      }

      this.$emit("changeInRadioFilter", {
        type: "service_provider",
        value: tmp
      });
    },
    changeServiceType(event) {
      let tmp = event.target.value;

      if (event.target.value === this.service_type) {
        this.service_type = "";
        tmp = "";
      }

      this.$emit("changeInRadioFilter", {
        type: "service_type",
        value: tmp
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.services-filter {
  margin-top: 64px;

  @media screen and (max-width: 3199px) {
    margin-top: 22px;
  }
  & + h2 {
    margin-top: 64px;

    @media screen and (max-width: 3199px) {
      margin-top: 22px;
    }
  }
}
</style>
